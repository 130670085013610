// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.narrow {
  width: 155px;
}

button.narrow {
  height: 54px;
  padding-bottom: 13px;
  padding-top: 12px;
}`, "",{"version":3,"sources":["webpack://./src/app/image-overlayer/image-overlayer.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AACA;EACE,YAAA;EAEA,oBAAA;EACA,iBAAA;AACF","sourcesContent":[".narrow {\n  width: 155px;\n}\nbutton.narrow {\n  height: 54px;\n\n  padding-bottom: 13px;\n  padding-top: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
