import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-thumbnail',
  standalone: true,
  imports: [
    NgIf
  ],
  templateUrl: './thumbnail.component.html',
  styleUrl: './thumbnail.component.scss'
})
export class ThumbnailComponent implements OnInit {
  @Input()
  file: File;

  url: SafeUrl;


  constructor(private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    if (this.file.type.startsWith('image/') && !this.file.name.endsWith('.tif') && !this.file.name.endsWith('.tiff') ) {
      const reader = new FileReader();

      reader.onload = (event: any) => {
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(event.target.result);
      };

      reader.onerror = (event: any) => {
        console.log('File could not be read: ' + event.target.error.code);
      };
      reader.readAsDataURL(this.file);
    }
  }
}
