import { Pipe, PipeTransform } from '@angular/core';
import {Kategorie} from '../konfiguration.service';

@Pipe({
  name: 'select'
})
export class SelectPipe implements PipeTransform {

  transform(kat: Kategorie[], prop, value): Kategorie {
    return kat.find(k => k[prop] === value);
  }

}
