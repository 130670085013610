import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';

export class Format {
  name: string;
  kategorie?: Kategorie;
  breite: number;
  hoehe: number;
  max: number;
}

export class Kategorie {
  name: string;
  partikel: string;
  sort: string;
  id: string;
}

export class Website {
  name: string;
  formats: Format[];
  kategorien: Kategorie[];
}

export class Konfig {
  name: string;
  selectedFonts: string[];
  websites: Website[];
}


@Injectable({
  providedIn: 'root'
})
export class KonfigurationService {

  defaultFonts = ['Roboto', 'Open Sans', 'Lato', 'Varela' ];

  konfig = new BehaviorSubject<Konfig>({
    name: 'empty',
    selectedFonts: this.defaultFonts,
    websites: []
  });

  public get STDKATS() {
    return [
      {
        name: 'Aufmacher',
        partikel: 'b',
        sort: '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25',
        id: this.randomStr()
      },
      {

        name: 'Artikelbild',
        partikel: 'b',
        sort: '2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25',
        id: this.randomStr()
      },
      {
        name: 'Teaser',
        partikel: 't',
        sort: 'a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,w,x,y,z',
        id: this.randomStr()
      }];
  }

  constructor() {
  }

  randomStr() {
    return Math.random().toString(36).substr(2, 5) + Math.random().toString(36).substr(2, 5);
  }

  public restore() {
    console.log('restore');
    const k = JSON.parse(window.localStorage.getItem('gujcookiecutterkonfig'));
    if (k) {
      k.websites.forEach(ws => {
        if (!ws.kategorien || ws.kategorien.length === 0) {
          ws.kategorien = this.STDKATS;
        }
      });
      const konfig = {
        name: k.name,
        selectedFonts: k.selectedFonts ? k.selectedFonts : ['Roboto'],
        websites: k.websites.sort((a, b) => a.name.localeCompare(b.name))
      };
      konfig.websites = konfig.websites.filter(website => website.formats.length > 0);
      this.konfig.next(konfig);
      this.loadFonts();
    }
  }

  public load(fileInput: File) {
    const reader = new FileReader();

    reader.onload = (e) => {
      const confFile = JSON.parse(reader.result as string);
      let websitesArr;
      let selectedFonts;
      if (Array.isArray(confFile)) {
        websitesArr = confFile as Website[];
        selectedFonts = this.defaultFonts;
      } else {
        websitesArr = confFile.websites as Website[];
        selectedFonts = confFile.selectedFonts;
      }

      const konfig = {
        name: fileInput.name,
        selectedFonts,
        websites: websitesArr.sort((a, b) => a.name.localeCompare(b.name))
      };
      window.localStorage.setItem('gujcookiecutterkonfig', JSON.stringify(konfig));
      this.konfig.next(konfig);
      this.loadFonts();
    };
    reader.readAsText(fileInput);

  }

  loadFonts() {
    for (const font of this.konfig.value.selectedFonts) {
      const style = document.createElement('link');
      const head = document.getElementsByTagName('head')[0];
      style.rel = 'stylesheet';
      style.href = `https://fonts.googleapis.com/css2?family=${encodeURI(font)}`;
      head.appendChild(style);
      const div = document.createElement('div');
      div.style.fontFamily = font;
    }

  }


  public save() {
    console.log('save');
    window.localStorage.setItem('gujcookiecutterkonfig', JSON.stringify(this.konfig.value));
    this.loadFonts();
  }

  getKonfig() {
    return this.konfig.asObservable();
  }
}

