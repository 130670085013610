import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {Format, Konfig, KonfigurationService, Website} from './konfiguration.service';
import {NgForm} from '@angular/forms';
import {ImageCropperComponent} from './ngx-image-cropper/component/image-cropper.component';
import {forkJoin, map, Observable, timer} from 'rxjs';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import smartcrop from 'smartcrop';
import {KeyValue} from '@angular/common';
import * as JSZip from 'jszip';
import {skipWhile, take} from 'rxjs/operators';
import {environment} from '../environments/environment';
import {Crop} from './cropResult';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {MatTable} from '@angular/material/table';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  version = environment.version;

  @ViewChildren(ImageCropperComponent) croppers: QueryList<ImageCropperComponent>;
  selectedIndex = 0;

  crops: Observable<{blob: Blob, filename: string}>[] = [];
  cropsDone = 0;

  konfig: Konfig = {
    name: null,
    selectedFonts: [],
    websites: [],
  };

  konfigFile: File;
  selectedSite: Website = null;
  kategorieNachFormat: {
    [key: string]: {
      files: File[],
      formats: Format[]
    }
  } = {};

  bilderMitPrefix: { [key: string]: { file: File, aufzaehlung: string, crops: Crop[] }[] } = {};

  artikel: string;
  kunde: string;
  alleBilder = -1;
  geladeneBilder: number;
  loading = false;
  cropping = false;

  downloadUrl: SafeResourceUrl | string;
  filename: string;

  constructor(protected konfigService: KonfigurationService, private domSanitizer: DomSanitizer) {

  }

  ngOnInit(): void {
    console.log('ngOnInit app.component.ts');
    this.konfigService.restore();
    this.konfigService.getKonfig().pipe(skipWhile(k => k === null), take(1)).subscribe(k => {
      this.selectedIndex = 1;
      this.konfigFile = new File([], k.name);
    });
    this.konfigService.getKonfig().subscribe(konfig => {
      this.konfig = konfig;
      this.konfigFile = new File([], this.konfig.name);
    });


  }

  katSort = (a: KeyValue<string, any>,
             b: KeyValue<string, any>) => {
    if (!a.value) {
      return -1;
    }
    if (!b.value) {
      return +1;
    }
    const ss = this.selectedSite;
    if (ss) {
      const x = ss.kategorien.findIndex(k => k.name === a.key);
      const y = ss.kategorien.findIndex(k => k.name === b.key);
      return x - y;
    } else {
      return 0;
    }
  }

  selectCropArea(crop: Crop, imgcropper: ImageCropperComponent) {
    console.log(imgcropper);
    const ch = crop.format.hoehe;
    const cw = crop.format.breite;

    smartcrop.crop(imgcropper.sourceImage.nativeElement, {width: cw, height: ch}).then(a => {
      console.log(a);
      const cropperPos = {
        x1: a.topCrop.x / (imgcropper.sourceImage.nativeElement.naturalWidth / imgcropper.sourceImage.nativeElement.width),
        y1: a.topCrop.y / (imgcropper.sourceImage.nativeElement.naturalHeight / imgcropper.sourceImage.nativeElement.height),
        x2: (a.topCrop.x + a.topCrop.width) /
          (imgcropper.sourceImage.nativeElement.naturalWidth / imgcropper.sourceImage.nativeElement.width),
        y2: (a.topCrop.y + a.topCrop.height) /
          (imgcropper.sourceImage.nativeElement.naturalHeight / imgcropper.sourceImage.nativeElement.height)
      };
      console.log(imgcropper.cropper);
      console.log(cropperPos);
      imgcropper.cropper = cropperPos;
    });
  }

  log(msg: string) {
    console.log(msg);
  }

  loadConfig( file: File) {
    this.konfigFile = file;
    this.konfigService.load(this.konfigFile);
  }



  generiere_crops() {
    this.loading = true;
    this.geladeneBilder = 0;
    let alleBilder = 0;
    console.log('gen_crops');
    this.crops = [];
    this.cropsDone = 0;
    for (const kat of Object.keys(this.kategorieNachFormat)) {
      if (!(kat in this.bilderMitPrefix)) {
        this.bilderMitPrefix[kat] = [];
      }
      console.log(kat);
      let a;
      if (this.kategorieNachFormat[kat].formats[0].kategorie) {
        a = this.kategorieNachFormat[kat].formats[0].kategorie.sort.split(',');
      } else {
        a = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14',
          '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25'];
      }
      if (this.kategorieNachFormat[kat].files) {
        this.bilderMitPrefix[kat] = this.kategorieNachFormat[kat].files.map((f, index) => {


          return {
            file: f,
            aufzaehlung: a[index],
            crops: this.kategorieNachFormat[kat].formats.map(format => {
              alleBilder += 1;
              const crop = new Crop(format);
              this.crops.push(crop.compressResultObs.pipe(map(blob => {
                this.cropsDone += 1;
                const filename = this.kunde + '_' + this.artikel + '_' + crop.format.kategorie.partikel + this.bilderMitPrefix[kat][index].aufzaehlung + '_' + crop.format.name + '.jpg';
                console.log('got compressResultObs: ' + filename + ' ' + blob?.size);
                return {
                  blob,
                  filename
                };
              })));
              return crop;
            })
          };
        });
      }
    }
    this.updateDownloadUrl();

    this.alleBilder = alleBilder;
  }

  imageCropped(event, crop: Crop, imgcropper) {

    if (event.imagePosition.x2 - event.imagePosition.x1 - event.width < 0) {
      crop.warnung = 'Ausschnitt zu klein gewählt. Das Bild wurde hochskaliert. ' +
        'Wählen sie einen größeren Auschnitt oder größeres Eingangsbild.';
    } else {
      crop.warnung = null;
    }
    crop.cropResult = event.file;
  }

  selectionChanged(event, f1: NgForm, f2: NgForm, f3: NgForm) {
    console.log(event.selectedIndex);
    this.selectedIndex = event.selectedIndex;
    if (event.selectedIndex === 1) {
      this.konfigService.save();
    }
    if (event.selectedIndex === 2) {
      if (f1.dirty || f2.dirty || !this.kategorieNachFormat) {
        this.kategorieNachFormat = {};
        for (const f of this.selectedSite.formats) {
          if (!(f.kategorie.name in this.kategorieNachFormat)) {
            this.kategorieNachFormat[f.kategorie.name] = {
              files: null,
              formats: []
            };
          }
          this.kategorieNachFormat[f.kategorie.name].formats.push(f);
        }
      }
    }
    if (event.selectedIndex === 3) {
      if (f1.dirty || f2.dirty || f3.dirty || !this.bilderMitPrefix) {
        this.generiere_crops();
        f1.form.markAsPristine();
        f2.form.markAsPristine();
        f3.form.markAsPristine();
      }
    }
    if (event.selectedIndex === 4) {
      this.cropping = true;
      timer(30).subscribe(() => {
        this.croppers.forEach(c => c.crop());
        this.cropping = false;
      });
    }
    if (event.selectedIndex === 5) {
      this.downloadUrl = null;
    }
  }

  cropperReady(f: Crop) {
    f.loaded = true;
    let alleBilder = 0;
    let geladenene = 0;
    for (const kat of Object.keys(this.bilderMitPrefix)) {
      for (const bild of this.bilderMitPrefix[kat]) {
        for (const crop of bild.crops) {
          alleBilder += 1;
          if (crop.loaded) {
            geladenene += 1;
          }
        }
      }
    }
    this.alleBilder = alleBilder;
    this.geladeneBilder = geladenene;
    if (this.alleBilder === this.geladeneBilder) {
      this.loading = false;
    }
  }



  updateDownloadUrl() {

    console.log('updateDownloadUrl');
    this.downloadUrl = null;
    forkJoin(this.crops).subscribe((crops ) => {
      console.log('Erzeuge Zip');
      const zip = new JSZip();
      for (const crop of crops) {
        zip.file(crop.filename, crop.blob, {
          createFolders: true // default value
        });
      }
      zip.generateAsync({type: 'blob'}).then(b => {
        console.log('download url gesetzt');
        this.downloadUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(b));
        this.filename = this.kunde + '_' + this.artikel + '.zip';

      });

    });


  }

  edit(name) {
    this.selectedIndex = 3;
    const e = document.getElementById(name);
    setTimeout(() => {
      e.scrollIntoView({behavior: 'smooth', block: 'start'});
    }, 10);

  }


  exc() {
    throw new Error('Dumm dumm dumm‚');
  }

  append(table: MatTable<File>, files, selected) {

    if (files) {
      files.push(...selected);
      console.log(files.map(f => f.name));
      table.renderRows();

      return files;
    } else {
      return [...selected];
    }
  }

  drop(table: MatTable<File>, files: File[], event: CdkDragDrop<string[]>) {
    moveItemInArray(files, event.previousIndex, event.currentIndex);
    console.log(files.map(f => f.name));
    table.renderRows();
  }


  remove( table: MatTable<File>, files: File[], i: number) {
    files.splice(i, 1);
    console.log(files.map(f => f.name));
    table.renderRows();
    return files;
  }

}
