// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.action-buttons {
  padding-bottom: 20px;
}

.headers-align .mat-expansion-panel-header-description {
  justify-content: space-between;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/app/konfiguration-editor/website-editor/website-editor.component.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;AACF;;AAEA;EACE,8BAAA;EACA,mBAAA;AACF","sourcesContent":[".action-buttons {\n  padding-bottom: 20px;\n}\n\n.headers-align .mat-expansion-panel-header-description {\n  justify-content: space-between;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
