// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  height: 48px;
  background-color: white;
  display: table;
}

.cdk-drag-preview td {
  flex-grow: 2;
  font-size: 14px;
  padding: 4px;
  vertical-align: middle;
}

.cdk-drag-placeholder {
  background-color: grey;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging .mat-row:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.mat-radio-button ~ .mat-radio-button {
  margin: 16px;
}

.hide {
  height: 0;
  overflow: hidden;
}

.backdrop {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: black;
  opacity: 0.5;
}
.backdrop mat-progress-spinner {
  position: absolute;
  left: 50%;
  margin-left: -50px;
  top: 50%;
  margin-top: -50px;
}

.noscroll {
  overflow-y: hidden;
  max-height: 100vh;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,kBAAA;EACA,qHAAA;EAIA,YAAA;EACA,uBAAA;EACA,cAAA;AAFF;;AAKA;EACE,YAAA;EACA,eAAA;EACA,YAAA;EACA,sBAAA;AAFF;;AAKA;EACE,sBAAA;AAFF;;AAKA;EACE,sDAAA;AAFF;;AAKA;EACE,sDAAA;AAFF;;AAKA;EACE,YAAA;AAFF;;AAKA;EACE,SAAA;EACA,gBAAA;AAFF;;AAKA;EACE,kBAAA;EACA,OAAA;EACA,QAAA;EACA,MAAA;EACA,SAAA;EACA,iBAAA;EACA,YAAA;AAFF;AAGE;EACE,kBAAA;EACA,SAAA;EACA,kBAAA;EACA,QAAA;EACA,iBAAA;AADJ;;AAKA;EACE,kBAAA;EACA,iBAAA;AAFF","sourcesContent":[".cdk-drag-preview {\n  box-sizing: border-box;\n  border-radius: 4px;\n  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px\n  rgba(0, 0, 0, 0.14), 0 3px 14px 2px\n  rgba(0, 0, 0, 0.12);\n\n  height: 48px;\n  background-color: white;\n  display: table;\n}\n\n.cdk-drag-preview td {\n  flex-grow: 2;\n  font-size: 14px;\n  padding: 4px;\n  vertical-align: middle;\n}\n\n.cdk-drag-placeholder {\n  background-color: grey;\n}\n\n.cdk-drag-animating {\n  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);\n}\n\n.cdk-drop-list-dragging .mat-row:not(.cdk-drag-placeholder) {\n  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);\n}\n\n.mat-radio-button ~ .mat-radio-button {\n  margin: 16px;\n\n}\n.hide {\n  height: 0;\n  overflow: hidden;\n}\n\n.backdrop {\n  position: absolute;\n  left: 0;\n  right: 0;\n  top: 0;\n  bottom: 0;\n  background: black;\n  opacity: 0.5;\n  mat-progress-spinner {\n    position: absolute;\n    left: 50%;\n    margin-left: -50px;\n    top: 50%;\n    margin-top: -50px;\n  }\n}\n\n.noscroll {\n  overflow-y: hidden;\n  max-height: 100vh;\n}\n\n.dropzone {\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
