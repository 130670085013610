import { Pipe, PipeTransform } from '@angular/core';
import {Website} from './konfiguration.service';

@Pipe({
  name: 'insertHeadline'
})
export class InsertHeadlinePipe implements PipeTransform {

  transform(value: Website[]): Array<[Website, boolean]> {
    const transformedArray: Array<[Website, boolean]> = [];

    for (let i = 0; i < value.length; i++) {
      // Der Boolean-Wert ist true, wenn sich der Anfangsbuchstabe ändert oder es das erste Element ist
      const isDifferent = i === 0 || value[i].name.toUpperCase()[0] !== value[i - 1].name.toUpperCase()[0];
      transformedArray.push([value[i], isDifferent]);
    }

    return transformedArray;
  }
}
