import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {Format, Kategorie, Konfig, KonfigurationService, Website} from '../konfiguration.service';
import {animate, style, transition, trigger} from '@angular/animations';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {FormArray, FormBuilder, NgForm} from '@angular/forms';
import {take} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';


@Component({
  selector: 'app-konfiguration-editor',
  templateUrl: './konfiguration-editor.component.html',
  styleUrls: ['./konfiguration-editor.component.scss'],
  animations: [
    trigger('items', [
      transition(':enter', [
        style({transform: 'scale(0.5)', opacity: 0}),  // initial
        animate('1s cubic-bezier(.8, -0.6, 0.2, 1.5)',
          style({transform: 'scale(1)', opacity: 1}))  // final
      ])
    ])
  ]
})
export class KonfigurationEditorComponent implements OnInit, AfterViewInit {
  @ViewChild('form') ngForm: NgForm;

  konfig: Konfig;

  get STDKATS() {
    return [
      {
        name: 'Aufmacher',
        partikel: 'a',
        sort: '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25',
        id: this.randomStr()
      },
      {

        name: 'Artikelbild',
        partikel: 'a',
        sort: '2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25',
        id: this.randomStr()
      },
      {
        name: 'Teaser',
        partikel: '',
        sort: 'a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,w,x,y,z',
        id: this.randomStr()
      }];
  }

  fonts: Observable<{
    items: any[],
    kind: string
  }> = null;

  dataurl: SafeResourceUrl;

  aa = (new Date().getDay() + 1).toString();

  filename = 'websites-' + new Date().getFullYear().toString() +
    (new Date().getMonth() + 1).toString().padStart(2, '0') + (new Date().getUTCDate() + 1).toString().padStart(2, '0') + '.json';

  constructor(public konfigService: KonfigurationService, private domSanitizer: DomSanitizer, private httpClinet: HttpClient) {
    this.fonts = this.httpClinet.get<{
      items: any[],
      kind: string
    }>('https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyB2UPGADli2oM0MYQtz-crIA51Zza_Xiak');
  }

  trackByIndex(index: number, thing: any) {
    return index;
  }

  randomStr() {
    return Math.random().toString(36).substr(2, 5) + Math.random().toString(36).substr(2, 5);
  }


  ngOnInit() {
    this.konfigService.getKonfig().pipe(take(1)).subscribe(konfig => {
      this.konfig = konfig;
      this.changes_done();
    });
  }

  compareWith(a: Kategorie, b: Kategorie) {

    if (a && b) {
      return a.id === b.id;
    }
    return !(a || b);
  }


  changes_done() {
    const jsonse = JSON.stringify( {
      websites : this.konfig.websites.filter(website => website.formats.length > 0),
      selectedFonts : this.konfig.selectedFonts,
    }, null, 2);
    const blob = new Blob([jsonse], {type: 'application/gzip'});
    const url = URL.createObjectURL(blob);
    if (this.dataurl) {
      URL.revokeObjectURL(this.dataurl as string);
    }
    this.dataurl = this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    this.konfigService.konfig.next(this.konfig);

  }


  ngAfterViewInit(): void {

  }
}
